import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  Loader,
  Modal,
  ModalClosableFooter,
  Popconfirm,
  Select,
  Space,
  Typography,
} from "@ogury/design-system";
import { useTranslation } from "react-i18next";
import { Experience } from "@ogury/motionly-ws-api/ws";
import * as PropTypes from "prop-types";
import { copyText, useNotificationService } from "Legacy/utils";
import { applicationService, backofficeService, experienceService, siteService, SolutionVendor } from "Legacy/services";
import { ModalWidths } from "Legacy/components/Modal/Modal";

const oguryCallbacksApplicationName = "Callbacks";

export default function ThirdPartyTagModal({ open, site, experience, onClose }) {
  const notificationService = useNotificationService();
  const [t] = useTranslation();
  const [dspList, setDspList] = useState();
  const [applicationList, setApplicationList] = useState();
  const [dspSelected, setDspSelected] = useState();
  const [applicationSelected, setApplicationSelected] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [working, setWorking] = useState(false);
  const [minified, setMinified] = useState(false);
  const [thirdPartyTag, setThirdPartyTag] = useState();

  const entityIsDraft = useMemo(
    () => (experience === undefined ? site : experience)?.state === Experience.StateEnum.Draft,
    [experience, site]
  );

  async function load() {
    setLoading(true);

    try {
      const _dspList = await backofficeService.getDspList();
      const _applicationList = await applicationService.list();
      setDspList(_dspList);
      setApplicationList(_applicationList);
      setDspSelected(_dspList?.find(dsp => dsp.value === SolutionVendor.Ogury) || _dspList[0]);
      setApplicationSelected(_applicationList.find(application => application.name === oguryCallbacksApplicationName));
    } catch (error) {
      console.warn(error);
      setError(t("experiences.embed.modal.thirdParty.errorLoadingDspOrApplication"));
    } finally {
      setLoading(false);
    }
  }

  async function loadTag() {
    setWorking(true);
    try {
      if (experience) {
        const tag = await experienceService.getThirdPartyTag(
          experience.id,
          minified,
          applicationSelected?.publicId,
          dspSelected?.value
        );
        setThirdPartyTag(tag);
      } else if (site) {
        const tag = await siteService.getThirdPartyTag(
          site.id,
          minified,
          applicationSelected?.publicId,
          dspSelected?.value
        );
        setThirdPartyTag(tag);
      }
    } catch (error) {
      console.warn(error);
      setError(t("experiences.embed.modal.thirdParty.errorLoadingThirdPartyTag"));
    } finally {
      setWorking(false);
    }
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    load();
  }, [open]); //eslint-disable-line

  useEffect(() => {
    if (!open) {
      return false;
    }

    if (dspSelected && applicationSelected) {
      loadTag();
    }
  }, [open, dspSelected, applicationSelected, minified]); //eslint-disable-line

  function handleOnClickDownload() {
    const blob = new Blob([thirdPartyTag], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${experience?.name || site?.name || "third-party-tag"}.txt`;
    link.href = url;
    link.click();
  }

  function renderLoading() {
    return <Loader />;
  }

  function renderThirdPartyTag() {
    if (working) {
      return <Loader />;
    }
    return <Input.TextArea rows={20} width="100%" disabled required value={thirdPartyTag} />;
  }

  function renderContent() {
    return (
      <Space size="s" direction="vertical" style={{ width: "100%" }}>
        <div>
          <FormControlLabel
            label={t("experiences.embed.solutionVendor")}
            hint={t("experiences.embed.solutionVendorTooltip")}
          />
          <Select
            showSearch
            width="100%"
            disabled={experience}
            value={dspSelected?.value}
            options={dspList?.map(dsp => ({ value: dsp.value, label: dsp.text }))}
            onChange={dspValue => setDspSelected(dspList?.find(dsp => dsp.value === dspValue))}
          />
        </div>
        <div>
          <FormControlLabel
            label={t("experiences.embed.modal.thirdParty.application")}
            hint={t("experiences.embed.modal.thirdParty.applicationTooltip")}
          />
          <Select
            showSearch
            width="100%"
            disabled={true}
            value={applicationSelected?.id}
            options={applicationList?.map(application => ({ value: application.id, label: application.name }))}
            onChange={applicationId =>
              setApplicationSelected(
                applicationList?.find(application => application.id?.toString() === applicationId?.toString())
              )
            }
          />
        </div>
        <Checkbox checked={minified} onChange={setMinified}>
          {t("experiences.embed.minifiedTag")}
        </Checkbox>
        {renderThirdPartyTag()}
      </Space>
    );
  }

  async function handleOnClickCopy() {
    await copyText(thirdPartyTag);
    notificationService.notifyInfo(t("app.notifications.copiedToClipboard"));
  }

  function renderFooter() {
    const createButton = (actionType, handleOnClick, titleKey, confirmKey) => {
      const ButtonComponent = (
        <Button
          loading={working || loading}
          disabled={working || loading}
          onClick={entityIsDraft ? undefined : handleOnClick}
        >
          {t(`actions.${actionType}`)}
        </Button>
      );

      return entityIsDraft ? (
        <Popconfirm
          cancelText={t("actions.cancel")}
          title={t(`experiences.embed.modal.thirdParty.${titleKey}`)}
          okText={t(`experiences.embed.modal.thirdParty.${confirmKey}`)}
          onConfirm={handleOnClick}
        >
          {ButtonComponent}
        </Popconfirm>
      ) : (
        ButtonComponent
      );
    };

    const copyButton = createButton(
      "copy",
      handleOnClickCopy,
      experience ? "experienceCopyWarning" : "siteCopyWarning",
      "copyAnyway"
    );
    const downloadButton = createButton(
      "download",
      handleOnClickDownload,
      experience ? "experienceDownloadWarning" : "siteDownloadWarning",
      "downloadAnyway"
    );

    return (
      <ModalClosableFooter
        actions={
          <>
            {copyButton}
            {downloadButton}
          </>
        }
      />
    );
  }

  function renderExplanations() {
    return (
      <Space size="s" direction="vertical">
        <Typography.P2Regular>{t("experiences.embed.modal.thirdParty.explanation")}</Typography.P2Regular>
        {entityIsDraft && (
          <Alert
            type="warning"
            description={t(
              `experiences.embed.modal.thirdParty.${experience === undefined ? "site" : "experience"}Warning`
            )}
            closable={false}
            showIcon
          />
        )}
        <Alert
          type="info"
          description={
            <div style={{ whiteSpace: "pre-line" }}>{t(`experiences.embed.modal.thirdParty.downloadInfo`)}</div>
          }
          closable={false}
          showIcon
        />
      </Space>
    );
  }

  function renderError() {
    return <Alert closable={false} type="error" message={error} />;
  }

  return (
    <Modal
      //TODO set the width from a value in the design system?
      width={ModalWidths.Medium}
      footer={renderFooter()}
      open={open}
      title={t("experiences.embed.modal.thirdPartyTagTitle")}
      onCancel={onClose}
    >
      <Space direction="vertical">
        {renderExplanations()}
        {error && renderError()}
        {!error && loading && renderLoading()}
        {!error && !loading && dspSelected && applicationSelected && renderContent()}
      </Space>
    </Modal>
  );
}

ThirdPartyTagModal.propTypes = {
  open: PropTypes.bool,
  site: PropTypes.object,
  experience: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
