import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./ExperienceEmbedModal.scss";
import { PageLoader, ThirdPartyTagModal } from "Legacy/components";
import { Environment } from "Legacy/services";
import { ExperienceIntegrationECommerceModal, ExperienceIntegrationWebModal } from "./components";

export default function ExperienceEmbedModal({ experience, open, environment, onCancel = () => {} }) {
  const [t] = useTranslation();
  const [working, setWorking] = useState(false);

  const renderEnvironment = () => {
    switch (environment) {
      default:
        return null;
      case Environment.Web:
        return (
          <ExperienceIntegrationWebModal
            open={open}
            experience={experience}
            renderLoader={() => <PageLoader message={t("experiences.embed.modal.pleaseWait")} />}
            working={working}
            onWorking={setWorking}
            onClose={onCancel}
          />
        );
      case Environment.ECommerce:
        return (
          <ExperienceIntegrationECommerceModal
            open={open}
            experience={experience}
            renderLoader={() => <PageLoader message={t("experiences.embed.modal.pleaseWait")} />}
            onWorking={setWorking}
            onClose={onCancel}
          />
        );
      case Environment.ThirdParty:
        return (
          <ThirdPartyTagModal
            open={open}
            experience={experience}
            working={working}
            onWorking={setWorking}
            onClose={onCancel}
          />
        );
    }
  };
  return renderEnvironment();
}

ExperienceEmbedModal.propTypes = {
  open: PropTypes.bool,
  experience: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    data: PropTypes.shape({
      templateId: PropTypes.string,
      templateVersion: PropTypes.number,
    }),
  }),
  environment: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};
